import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WrapperAbout from "../components/WrapperAbout"

const InfoPage = () => (
  <Layout>
    <SEO title="Charlotte Louis" />
    <WrapperAbout />
  </Layout>
)

export default InfoPage
