import React from "react"
import { Link } from "gatsby"

import Slider from "react-slick"
import "@carousel/slick.css"
import "@carousel/slick-theme.css"
import "./carousel/slick.css"
import "./carousel/slick-theme.css"
import { StaticImage } from "gatsby-plugin-image"
import {
  StyledFooter,
  StyledNavigation,
  StyledNumber,
} from "./footer/StyledFooter"
import { InfosList } from "./footer/GalleryList"
import Contact from "./footer/Contact"
import DiegoCredit from "./DiegoCredit"
import WrapperTemplate, { StyledPicture } from "./WrapperTemplate"

export default function WrapperAbout() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const imgsettings = {
    className: "image",
    height: 1600,
    quality: 70,
    objectFit: "contain",
    placeholder: "none",
    alt: "image",
  }
  // This create the carousel "states" we will later connect

  return (
    <>
      <WrapperTemplate id="about">
        <DiegoCredit />
        <Link to="/">
          <StyledPicture className="cover-pic" id="about-pic">
            <StaticImage {...imgsettings} src="../images/goodbye_10.jpg" />
          </StyledPicture>
        </Link>
        <StyledFooter>
          <Contact about={true} />
          <StyledNavigation>
            <InfosList />
            <StyledNumber>
              <Slider {...settings}>
                <p>00</p>
              </Slider>
            </StyledNumber>
          </StyledNavigation>
        </StyledFooter>
        <DiegoCredit />
      </WrapperTemplate>
    </>
  )
}
